import React, { FC, useCallback, useMemo, useState } from "react";
import Fuse from "fuse.js";

import {
  Layout,
  SEO,
  BlogFilter,
  HomeHeader,
  BlogList,
  Footer,
} from "components";
import { Frontmatter } from "types/frontmatter";
import { graphql, useStaticQuery } from "gatsby";
import { Meta } from "types/meta";

export interface BlogPostsQuery {
  allMdx: {
    nodes: Array<{
      frontmatter: Frontmatter;
      id: string;
    }>;
  };
  file: {
    childMetaJson: Meta;
  };
}

const BlogPostListTemplate: FC = () => {
  const query = useStaticQuery<BlogPostsQuery>(
    graphql`
      query blogPosts {
        allMdx(filter: { frontmatter: { categories: { ne: "menuItem" } } }) {
          nodes {
            frontmatter {
              title
              date
              slug
              categories
              keywords
              encourager
              teacher
            }
            id
          }
        }
        file(relativePath: { eq: "meta.json" }) {
          childMetaJson {
            nextStream {
              link
            }
          }
        }
      }
    `
  );

  const data = useMemo<
    Array<{
      frontmatter: Frontmatter;
      id: string;
      tags: string[];
    }>
  >(() => query.allMdx.nodes.map((item) => ({ ...item, tags: [] })), [query]);

  const [dataState, setDataState] = useState(data);

  const onCategorySelect = useCallback<(category: string) => void>(
    (category) => {
      setDataState(
        dataState.filter((item) =>
          (item.frontmatter.categories || []).includes(category)
        )
      );
    },
    [setDataState, dataState]
  );

  const onTermFilter = useCallback<(term: string) => void>(
    (term) => {
      const results = new Fuse(data, {
        keys: [
          {
            name: "frontmatter.keywords",
            weight: 7,
          },
          {
            name: "frontmatter.categories",
            weight: 6,
          },
          {
            name: "frontmatter.date",
            weight: 5,
          },
          {
            name: "frontmatter.slug",
            weight: 1,
          },
          {
            name: "frontmatter.title",
            weight: 2,
          },
          {
            name: "frontmatter.encourager",
            weight: 3,
          },
          {
            name: "frontmatter.teacher",
            weight: 4,
          },
        ],
        includeMatches: true,
        includeScore: true,
      })
        .search(term)
        .filter((item) => `${item.score}`.charAt(0) !== "0");

      setDataState(
        results.length > 0
          ? results.map((item) => ({
              ...item.item,
            }))
          : term
          ? []
          : data
      );
    },
    [setDataState, dataState]
  );

  const refreshPage = useCallback(() => {
    location.reload(true);
  }, []);

  return (
    <Layout>
      <SEO
        title={"Alkalmak"}
        description={
          "A Gödöllői Baptista Gyülekezet alkalmairól készült felvételek"
        }
      />
      <HomeHeader meta={query.file.childMetaJson} />
      <BlogFilter
        onCategorySelect={onCategorySelect}
        onTermFilter={onTermFilter}
      />
      <div
        className="bg-white rounded-lg shadow-lg flex flex-row items-center cursor-pointer hover:shadow-xl transition duration-100 ease-in p-4 mx-4 mt-4 md:mx-8 lg:mx-16 lg:mt-8 xl:mx-64"
        onClick={refreshPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#4681bd"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-12 mr-4"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        Ha nem jelennek meg a legutóbbi alkalmak, kattintson ide!
      </div>
      <BlogList data={dataState} />
      <div className="mb-32"></div>
      <Footer />
    </Layout>
  );
};

export default BlogPostListTemplate;
